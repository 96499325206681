.works-0 {
  display: flex;
  flex-direction: column;
  grid-area: top;
  overflow: hidden;

  @media only screen and (min-width: 768px) {
    & .works-text {
      max-width: 75%;
      display: flex;
      flex-direction: column;
      // justify-content: flex-end;
      // align-items: flex-end;
    }
  }
}

.works-1 {
  display: flex;
  flex-direction: column;
  grid-area: middle;
  overflow: hidden;
  @media only screen and (min-width: 768px) {
    // flex-direction: row-reverse;
    // align-items: flex-end;
    justify-content: flex-end;
  }
}

.works-2 {
  display: flex;
  flex-direction: column;
  grid-area: bottom;

  overflow: hidden;
  @media only screen and (min-width: 768px) {
    & .works-text {
      max-width: 75%;
      display: flex;
      flex-direction: column;
      // justify-content: flex-end;
      // align-items: flex-end;
    }
  }
}

.works-3 {
  display: flex;
  flex-direction: column;
  grid-area: last;
  overflow: hidden;
  margin-bottom: 2rem;
  @media only screen and (min-width: 768px) {
    // flex-direction: row-reverse;
    // align-items: flex-end;
    justify-content: flex-end;
  }
}

.animation-container {
  overflow: hidden;
}

.absolute-div {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.absolute-div:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(122, 36, 235, 0.2);
  z-index: 999;
  border-radius: 10px;
}

.absolute-div:hover {
  animation: zoom 10s;
}

@keyframes zoom {
  0% {
    transform: scale(1.2);
  }
  25% {
    transform: scale(1.3);
    background-color: rgb(122, 36, 235, 0.6);
  }
}

.projects {
  display: grid;
  gap: 2rem;
  grid-template-areas:
    'top'
    'middle'
    'bottom '
    'last';
  @media only screen and (min-width: 600px) {
    grid-template-areas:
      '.  . . top'
      '. . middle .'
      ' .  . . bottom '
      '. . last .';
  }
}
