html {
  font-size: 62.5%;
  font-family: 'Saira Extra Condensed', sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: black;
  text-decoration: none;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 600px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
}

.height-70 {
  min-height: 70vh;
}

.height-80 {
  min-height: 80vh;
}
