.animation-container {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1.5rem 0;
  color: white;
  margin-top: 4rem;
  overflow: hidden;
  @media only screen and (min-width: 768px) {
    overflow: hidden;
  }
}

.animation-container--links a {
  color: white;
  margin: 1rem;
}

.animation-container--links {
  font-size: 3rem;

  display: block;
  width: 100%;
  transform: translateX(100%);
  animation: move 8s linear infinite;
  @media only screen and (min-width: 768px) {
    width: 100%;
    font-size: 4rem;
  }
}

@keyframes move {
  to {
    transform: translateX(-100%);
  }
}

.animation-container--links:hover {
  animation-play-state: paused;
}
