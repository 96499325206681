.header {
  display: flex;
  font-size: 2rem;
  align-items: center;
  gap: 1.5rem;
  span {
    color: #ddff3c;
  }
}

img {
  max-width: 80%;
  @media only screen and (min-width: 768px) {
    max-width: 50%;
  }
}

.absolute-div:hover {
  animation: zoom 4s;
}

@keyframes zoom {
  0% {
    transform: scale(1.2);
  }
  25% {
    transform: scale(1.3);
  }
}
