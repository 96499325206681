.button {
  background-color: #7a22eb;
  color: #fff;
  padding: 1.5rem 3.5rem;
  border: none;
  border-radius: 10px;
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 600;
  position: relative;

  & span {
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    animation: flip 4s infinite;
    animation-delay: calc(0.1s * var(--k));
  }

  &:hover {
    background-color: rgb(122, 34, 235, 0.6);
  }

  &:active {
    background-color: #fff;
    color: #7a22eb;
    border: 1px solid #7a22eb;
  }
}

@keyframes flip {
  0%,
  80% {
    transform: rotateY(360deg);
  }
}
