.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  animation: fadeIn 1s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal-content {
  background-color: rgb(0, 0, 0, 0.6);
  border-radius: 8px;
  padding: 2rem;
  max-width: 80%;

  @media only screen and (min-width: 768) {
  }
}

.modal-title {
  margin: 0;
  color: #ddff3c;
  @media only screen and (min-width: 768px) {
    font-size: 3.5rem;
    text-align: center;
  }
}

.modal-img {
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    border-radius: 1rem;
  }
}

.modal-body {
  color: #eee;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  & p {
    display: none;
  }
  @media only screen and (min-width: 768px) {
    & p {
      display: block;
      max-width: 60%;
    }
  }
}

.modal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  gap: 1rem;

  & a {
    display: flex;
    border-radius: 50%;
    padding: 1rem;
    background-color: #7a22eb;
    font-size: 2.5rem;
    color: #ddff3c;
    text-align: center;
  }

  & span {
    text-transform: uppercase;
    color: #ddff3c;
  }
}
