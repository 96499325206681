.text-intro {
  text-transform: uppercase;
  line-height: 0.85;
  padding-left: 0;
  padding-right: 0;

  @media only screen and (min-width: 1200px) {
    max-width: 50%;
    padding-left: 4rem;
    padding-right: 4rem;
  }

  & h6 {
    font-size: 1.6rem;
    color: #7a22eb;
  }

  & p {
    font-weight: 600;
    margin: 0 0 2.5rem 0;
    font-size: 6.5rem;

    @media only screen and (min-width: 600px) {
      font-size: 8.5rem;
    }

    @media only screen and (min-width: 800px) {
      font-size: 10rem;
    }

    @media only screen and (min-width: 992px) {
      font-size: 12rem;
    }
  }
}
