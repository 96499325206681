.about {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (min-width: 768px) {
    max-width: 40%;
  }

  & h1 {
    color: #ddff3c;
    font-size: 1.4rem;
  }

  & p {
    color: #fff;
    font-size: 2rem;
  }
}

.about-main {
  justify-content: flex-end;
}
